import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  clock() {
    const now = new Date();
    const TwentyFourHour = now.getHours();
    let hour = now.getHours();
    let min: any = now.getMinutes();
    let mid = 'pm';
    if (min < 10) {
      min = '0' + min;
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (hour === 0) {
      hour = 12;
    }
    if (TwentyFourHour < 12) {
       mid = 'am';
    }

    return hour + ':' + min + mid;


  }


}
